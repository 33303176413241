<template>
  <div
    class="page case-studies"
    :loaded="Boolean(cases) && Boolean(page)"
  >
    <section class="top-section" v-if="page">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${page.image})` }">
          <div class="content">
            <h1 v-html="page.title.replace(/\n/g, '<br>')"></h1>
            <h3 v-html="page.subtitle.replace(/\n/g, '<br>')"></h3>
          </div>
        </div>
      </div>
    </section>

    <section class="section" v-if="page">
      <div class="wrapper">
        <div class="section-footer">
          <p v-html="page.description.replace(/\n/g, '<br>')"></p>
        </div>
      </div>
    </section>

    <section class="section metrics" v-if="home && home.metrics">
      <div class="wrapper">
        <div class="container">
          <div class="block" v-for="(metric, i) in home.metrics.filter((metric) => metric.value && metric.label)" :key="`metric-${i}`">
            <div class="block-content">
              <b>{{metric.value}}</b>
              {{metric.label}}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section cases-list" ref="clients">
      <div class="wrapper" v-if="cases">
        <!-- <div class="selectors">
          <div class="selector">
            <Dropdown
              :options="sectorOptions"
              :value="selectedSector"
              @change="selectedSector = $event.value"
            />
          </div>
        </div> -->
        <div class="container">
          <router-link
            class="case"
            v-for="cs in cases.filter(c => !selectedSector || (c.sectors && c.sectors.includes(selectedSector)))"
            :to="`/case-studies/${encodeURIComponent(cs.company)}`"
            :key="cs.id"
          >
            <div class="case-image">
              <img
              :src="cs.logo"
              :alt="cs.name"
              />
            </div>
            <div class="case-role">{{ cs.role }}</div>
            <h5>{{cs.name}}</h5>
            <div class="case-company">{{cs.company}}</div>
            <!-- <div class="case-sector" v-if="cs.sectors && cs.sectors.length">{{cs.sectors[0]}}</div> -->
          </router-link>
        </div>
      </div>
    </section>

    <div class="section testimonials" v-if="testimonials.length">
      <div class="wrapper">
        <div class="container">
          <div class="section-title centered">
            <h3>Testimonials</h3>
            <h5>Subtitle line here</h5>
          </div>
          <div class="testimonials-list">
            <div class="testimonial" v-for="testimonial in testimonials" :key="testimonial.id">
              <div class="testimonial-image">
                <div class="testimonial-image-container">
                  <div class="video" v-if="testimonial.video">
                    <iframe
                      :src="testimonial.video
                        .replace('watch?v=', 'embed/')
                        .replace('youtu.be/', 'youtube.com/embed/')"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <img
                    v-else
                    :src="testimonial.image"
                    :alt="testimonial.name"
                  />
                </div>
              </div>
              <div class="testimonial-content">
                <div class="testimonial-author">
                  <div class="testimonial-name">{{testimonial.name}}</div>
                  <div class="testimonial-role">{{testimonial.role}} - {{ testimonial.company }}</div>
                </div>
                <div class="testimonial-text" v-html="testimonial.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import Dropdown from "./Dropdown.vue";
import FeaturedCase from "./FeaturedCase.vue";

export default {
  name: "CaseStudies",
  components: {
    Icon,
    Dropdown,
    FeaturedCase,
  },
  data() {
    return {
      cases: null,
      page: null,
      selectedSector: null,
      testimonials: [],
      home: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    featuredCase() {
      if (!this.cases) return null;
      return this.cases.find(c => c.featured) || null;
    },
    sectorOptions() {
      const sectors = this.cases
        .reduce((cases, c) => {
          if (c.sectors) {
            return [
              ...cases,
              ...c.sectors.filter(s => !cases.includes(s)),
            ];
          }
          return cases;
        }, [])
        .sort((a, b) => a.localeCompare(b))
        .map(s => ({
          label: s[0].toUpperCase() + s.substr(1),
          value: s,
        }));
      return [
        { label: 'All sectors', value: null },
        ...sectors,
      ];
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
    page() {
      this.scrollToClients();
    },
    featuredCase() {
      this.scrollToClients();
    },
  },
  methods: {
    scrollToClients() {
      if (this.$route.hash === '#clients' && this.page && this.featuredCase) {
        this.$nextTick(() => {
          this.scrollToY(this.$refs.clients.offsetTop);
        });
      }
    },
    getItems() {
      if (this.pageRef) this.pageRef();
      if (this.casesRef) this.casesRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("casestudies")
        .onSnapshot((page) => {
          this.page = {
            title: "",
            subtitle: "",
            description: "",
            image: "",
            ...page.data(),
          };
        });
      this.homeRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}settings/homepage`)
        .onSnapshot((settings) => {
          this.home = settings.data();
        });
      this.casesRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}cases`)
        .where('public', '==', true)
        .onSnapshot((cases) => {
          this.cases = cases.docs
            .map((doc) => doc.data())
            .sort((a, b) => b.created_at - a.created_at);
        });
      this.testimonialsRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}testimonials`)
        .onSnapshot((testimonials) => {
          this.testimonials = testimonials.docs
            .map((doc) => doc.data())
            .sort((a, b) => b.created_at - a.created_at);
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.casesRef) this.casesRef();
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 64px;
  line-height: 1.166666;
  color: var(--Dark-Blue);
}
.section-title h5 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  overflow: hidden;
  padding-top: 53.4%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 64px 96px;
  background: linear-gradient(36deg, rgba(14, 58, 90, 0.80) 13.82%, rgba(14, 58, 90, 0.50) 32.99%, rgba(14, 58, 90, 0.00) 64.59%);
}
.top-section .container .content .logo {
  display: block;
  width: 414px;
  max-width: 100%;
}
.top-section .container .content h1 {
  font-size: 128px;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 64px;
  margin-bottom: 32px;
  line-height: 1.1625;
  max-width: 900px;
}
.top-section .container .content p {
  font-size: 18px;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 42%;
  padding: 0 48px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 58%;
  padding: 0 48px;
  font-size: 24px;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.image-text .container {
  display: flex;
}
.image-text .image {
  width: 580px;
  object-fit: cover;
  border-radius: 12px;
}
.image-text .content {
  flex-grow: 1;
  padding: 0 96px 16px;
}
.image-text h3 {
  font-size: 64px;
  line-height: 1.166666;
  margin-bottom: 24px;
  color: var(--Metallic-Seaweed);
}
.image-text h5 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.166666;
  color: var(--Dark-Blue);
  margin-bottom: 32px;
}
.image-text p {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}
.image-text p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px 0;
}

.metrics .container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
.metrics .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(25% - 24px);
  padding: 48px 40px;
  border-radius: 8px;
  background: var(--Dark-Blue);
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
.metrics .block b {
  display: block;
  font-size: 72px;
}

.cases-list .container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
.cases-list .case {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(33.33333333% - 22px);
  padding: 40px 80px 24px;
  text-align: center;
  color: var(--Dark-Blue);
  font-size: 20px;
  border-radius: 12px;
  transition: all 0.2s ease-out;
}
.cases-list .case:hover {
  box-shadow: 0px 24px 32px 0px rgba(170, 170, 170, 0.08);
}
.cases-list .case h5 {
  background: var(--Dark-Blue);
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding: 8px 16px;
  margin: 8px 16px;
  border-radius: 4px;
}
.cases-list .case .case-image {
  position: relative;
  padding-top: 100%;
  margin-bottom: 16px;
}
.cases-list .case .case-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
}

.testimonials .testimonial {
  display: flex;
  align-items: center;
  gap: 96px;
  margin-bottom: 32px;
}
.testimonials .testimonial .testimonial-image {
  width: 580px;
  flex-shrink: 0;
}
.testimonials .testimonial .testimonial-image-container {
  position: relative;
  padding-top: 56.25%;
}
.testimonials .testimonial .testimonial-image img,
.testimonials .testimonial .testimonial-image iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.testimonials .testimonial .testimonial-content {
  flex-grow: 1;
  padding: 24px 0;
}
.testimonials .testimonial .testimonial-name {
  font-size: 36px;
  font-weight: 600;
  color: var(--Dark-Blue);
  margin-bottom: 8px;
}
.testimonials .testimonial .testimonial-role {
  font-size: 18px;
  color: var(--Dark-Blue);
  opacity: 0.46;
  font-weight: 600;
}
.testimonials .testimonial .testimonial-text {
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.1666666;
  margin-top: 24px;
}

@media screen and (max-width: 1280px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 48px;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 300px;
  }
  .top-section .container .content h1 {
    font-size: 96px;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 28px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 21px;
  }
  .metrics .container {
    gap: 16px;
  }
  .metrics .block {
    width: calc(25% - 12px);
  }
  .metrics .block b {
    font-size: 56px;
  }
  .cases-list .case {
    padding: 40px 40px 24px;
  }
  .testimonials .testimonial {
    gap: 64px;
  }
  .testimonials .testimonial .testimonial-image {
    width: 40%;
  }
  .testimonials .testimonial .testimonial-text {
    font-size: 21px;
  }
}

@media screen and (max-width: 1024px) {
  .cases-list .case {
    padding: 24px 0;
  }
}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 40px;
  }
  .section-title h5 {
    font-size: 24px;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container {
    min-height: 400px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 220px;
  }
  .top-section .container .content h1 {
    font-size: 72px;
  }
  .top-section .container .content h3 {
    font-size: 48px;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
  .cases-list .case {
    width: calc(50% - 16px);
  }
}

@media screen and (max-width: 800px) {
  .metrics .block {
    width: calc(50% - 8px);
  }
  .testimonials .testimonial {
    flex-direction: column;
    gap: 0;
  }
  .testimonials .testimonial .testimonial-image {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .section-footer {
    font-size: 21px;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content .logo {
    width: 200px;
  }
  .top-section .container .content h1 {
    font-size: 48px;
  }
  .top-section .container .content h3 {
    font-size: 32px;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 21px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .metrics .block {
    width: 100%;
  }
  .cases-list .case {
    width: 100%;
  }
}
</style>
